<template>
  <div>
    <v-dialog
      v-model="viewDialog"
      persistent
      max-width="900px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t("Sale Invoice") }}: {{ formData.invoice_no }}
        </v-card-title>

        <v-card-text v-if="viewDialog">
          <v-simple-table
            id="print-area"
            class="print-table"
            dense
          >
            <template #default>
              <thead>
                <invoice-header
                  :date="formData.action_date"
                  :invoice-no="formData.invoice_no"
                  big-size
                />

                <tr>
                  <th
                    colspan="100"
                    class="h-35px text-body-1 py-4"
                    :class="{
                      'black--text': !$vuetify.theme.dark,
                    }"
                  >
                    <table>
                      <tr>
                        <td class="text-center h-35px">
                          {{ $t("Mr.") }}:
                          {{ formData.customer.name }}
                        </td>
                        <td class="text-center h-35px">
                          {{ $t("Phone") }}: {{ formData.customer.phone1 }}
                        </td>
                      </tr>
                    </table>
                  </th>
                </tr>

                <tr class="grey lighten-1">
                  <th
                    class="text-start"
                    :class="{
                      'black--text': !$vuetify.theme.dark,
                    }"
                  >
                    {{ $t("Item Name") }}
                  </th>
                  <th
                    class="text-center"
                    :class="{
                      'black--text': !$vuetify.theme.dark,
                    }"
                  >
                    {{ $t("Quantity") }}
                  </th>
                  <th
                    class="text-center"
                    :class="{
                      'black--text': !$vuetify.theme.dark,
                    }"
                  >
                    {{ $t("Sale Price") }}
                  </th>
                  <th
                    class="text-center"
                    :class="{
                      'black--text': !$vuetify.theme.dark,
                    }"
                  >
                    {{ $t("Total Price") }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="detail in formData.details"
                  :key="detail.id"
                >
                  <td class="text-start">
                    {{ detail.item_name }}
                  </td>
                  <td class="text-center">
                    {{ $_format_number(detail.quantity) }}
                  </td>
                  <td class="text-center">
                    {{ $_format_number(detail.sale_price) }} $
                  </td>
                  <td class="text-center">
                    {{ $_format_number(detail.total_sale_price) }} $
                  </td>
                </tr>

                <template v-if="formData.is_cash">
                  <tr>
                    <td
                      colspan="2"
                      class="h-35px pt-16"
                    >
                      <table class="pt-6">
                        <tr>
                          <td
                            class="border-none h-35px"
                            style="width: 35%;"
                          >
                            <strong>{{ $t("Cash Amount") }}: </strong>
                          </td>
                          <td class="border-none h-35px">
                            <div
                              class="d-inline-block px-4 rounded-sm black-border text-center py-1 font-weight-bold"
                              style="width: 50%"
                            >
                              {{ $_format_number(formData.net_price) }} $
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </template>

                <template v-else>
                  <tr>
                    <td
                      colspan="3"
                      class="h-35px pt-16"
                    >
                      <table class="pt-6">
                        <tr>
                          <td
                            class="border-none h-35px"
                            style="width: 35%;"
                          >
                            <strong>{{ $t("Total Price") }}: </strong>
                          </td>
                          <td class="border-none h-35px">
                            <div
                              class="d-inline-block px-4 rounded-sm black-border text-center py-1 font-weight-bold"
                              style="width: 50%"
                            >
                              {{ $_format_number(formData.net_price) }} $
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="border-none h-35px"
                            style="width: 35%;"
                          >
                            <strong>{{ $t("Paid Amount") }}: </strong>
                          </td>
                          <td class="border-none h-35px">
                            <div
                              class="d-inline-block px-4 rounded-sm black-border text-center py-1 font-weight-bold"
                              style="width: 50%"
                            >
                              {{ $_format_number(formData.is_cash ? formData.net_price : formData.pre_paid) }} $
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="border-none h-35px"
                            style="width: 35%"
                          >
                            <strong>{{ $t("Old Loan") }}: </strong>
                          </td>
                          <td class="border-none h-35px">
                            <div
                              class="d-inline-block px-4 rounded-sm black-border text-center py-1 font-weight-bold"
                              style="width: 50%"
                            >
                              {{ $_format_number(Math.abs(formData.old_loan)) }} $
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="border-none h-35px"
                            style="width: 35%;"
                          >
                            <strong>{{ $t("Current Loan") }}: </strong>
                          </td>
                          <td class="border-none h-35px">
                            <div
                              class="d-inline-block px-4 rounded-sm black-border text-center py-1 font-weight-bold"
                              style="width: 50%"
                            >
                              {{ $_format_number(Math.abs(formData.current_loan)) }} $
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>

                    <td
                      v-if="formData.monthly_plan"
                      colspan="3"
                      class="h-35px pt-16"
                    >
                      <table class="pt-6">
                        <tr>
                          <td
                            class="border-none h-35px"
                            style="width: 50%;"
                          >
                            <strong>{{ $t("No. of qist") }}: </strong>
                          </td>
                          <td class="border-none h-35px">
                            <div
                              class="d-inline-block px-4 rounded-sm black-border text-center py-1 font-weight-bold"
                              style="width: 80%"
                            >
                              {{ formData.monthly_plan.no_of_months }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="border-none h-35px"
                            style="width: 50%"
                          >
                            <strong>{{ $t("Monthly Receipt Amount") }}: </strong>
                          </td>
                          <td class="border-none h-35px">
                            <div
                              class="d-inline-block px-4 rounded-sm black-border text-center py-1 font-weight-bold"
                              style="width: 80%"
                            >
                              {{ formData.monthly_plan.monthly_receipt_amount }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="border-none h-35px"
                            style="width: 50%;"
                          >
                            <strong>{{ $t("Next Receipt Date") }}: </strong>
                          </td>
                          <td class="border-none h-35px">
                            <div
                              class="d-inline-block px-4 rounded-sm black-border text-center py-1 font-weight-bold"
                              style="width: 80%"
                            >
                              {{ $_format_date(formData.monthly_plan.next_receipt_date) }}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </template>
              </tbody>

              <tfoot
                class="d-none d-print-table-cell"
                style="position: fixed; bottom: 1rem; left: 2rem"
              >
                <tr>
                  <td
                    style="width: 100%"
                    class="border-none"
                  >
                    <div class="font-weight-bold text-subtitle-1">
                      ئیمزا
                    </div>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <app-form-tip />

          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            @click="$emit('close')"
          >
            {{ $t("Cancel") }}
          </v-btn>

          <v-btn
            color="primary"
            depressed
            small
            @click="$_print()"
          >
            <v-icon left>
              mdil-printer
            </v-icon>
            <span class="pe-2">
              {{ $t("Print") }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    viewDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
  },

  watch: {
    viewDialog(val) {
      if (val && this.formData.doPrint) {
        this.$_print()
      }
    }
  }
}
</script>
<style>
.h-35px {
  height: 35px !important;
}
.w-100 {
  width: 100% !important;
}
.border-none {
  border: none !important;
}
</style>
